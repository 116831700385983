import jwt_decode from 'jwt-decode';
import getQueryParams from '../utils/getQueryParams';
import config from '../config';
import API from '../services/api';

const b2bValue = getQueryParams('b2b');

const getAccessToken = () => {
  return localStorage.getItem('accessToken');
};

const isTokenTimeValid = () => {
  const currentAccessToken = jwt_decode(getAccessToken());
  return currentAccessToken.exp > new Date().getTime() / 1000;
};

const getIdToken = () => {
  return localStorage.getItem('idToken');
};

const getRefreshToken = () => {
  return localStorage.getItem('refreshToken');
};

const cleanTokens = () => {
  localStorage.setItem('accessToken', '');
  localStorage.setItem('refreshToken', '');
  localStorage.setItem('idToken', '');
};

const setAccessTokens = cognitoPayload => {
  const access = cognitoPayload?.data?.access_token;
  const refresh = cognitoPayload?.data?.refresh_token;
  const idToken = cognitoPayload?.data?.id_token;
  localStorage.setItem('accessToken', access);
  localStorage.setItem('refreshToken', refresh);
  localStorage.setItem('idToken', idToken);
};

const redirectToLogin = redirect => {
  redirect.push(`/login${b2bValue ? '?b2b=true' : ''}`);
};

const logout = redirectOption => {
  cleanTokens();
  redirectToLogin(redirectOption);
};

const getNewTokenFromAPI = async () => {
  const response = await API.post(
    `${config.cognito.authUrl}/customerguide-refresh-token`,
    { refreshToken: getRefreshToken() },
    {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json'
      }
    }
  );
  return response;
};

const refreshToken = async (snack, redirect, success) => {
  if (isTokenTimeValid()) {
    success();
    return;
  }

  const response = await getNewTokenFromAPI();
  setAccessTokens(response.data);
  success();
};

const getGoogleAuthUrl = () => {
  const { appClientId, redirectUri, cognitoAuthUrl } = config.cognito;
  const url = `${cognitoAuthUrl}/oauth2/authorize?identity_provider=Google&redirect_uri=${redirectUri}&response_type=TOKEN&client_id=${appClientId}&scope=openid`;
  return url;
};

export default {
  getAccessToken,
  setAccessTokens,
  redirectToLogin,
  getRefreshToken,
  cleanTokens,
  refreshToken,
  logout,
  getIdToken,
  getGoogleAuthUrl,
  getNewTokenFromAPI
};
