import React from 'react';
import { Route } from 'react-router-dom';
import LayoutContainer from '../../views/layoutComponents/LayoutContainer';
import MainLayout from '../../layouts/MainLayout';

const MainLayoutPublicRoute = ({ noWrap, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <MainLayout>
          {!noWrap && (
            <LayoutContainer>
              <Component {...props} />
            </LayoutContainer>
          )}
          {noWrap && <Component {...props} />}
        </MainLayout>
      )}
    />
  );
};

export default MainLayoutPublicRoute;
