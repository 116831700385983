import React, { useEffect, useState } from 'react';
import {
  CardContent,
  Divider,
  Grid,
  Paper,
  Typography
} from '@material-ui/core';
import AgentMessageCard from './AgentMessageCard';
import ClientMessageCard from './ClientMessageCard';

const Messages = ({ chat }) => {
  const [messages, setMessages] = useState(chat);
  useEffect(() => {
    setMessages(chat);
  }, [chat]);
  return (
    <>
      <CardContent>
        <Typography variant="h5" component="h2">
          Mensagens
        </Typography>
      </CardContent>
      <Divider />
      <Paper
        style={{
          maxHeight: 370,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse'
        }}
      >
        <CardContent>
          {messages.map((m, index) => {
            let div = (
              <Grid container spacing={1}>
                <Grid item lg={6} md={6} xs={12} />
                <Grid item lg={6} md={6} xs={12}>
                  <ClientMessageCard
                    message={m}
                    customerName={chat.customerName}
                  />
                </Grid>
              </Grid>
            );
            if (m.agent !== 'CLIENT')
              div = (
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} xs={12}>
                    <AgentMessageCard message={m} />
                  </Grid>
                </Grid>
              );
            // eslint-disable-next-line react/no-array-index-key
            return <div key={index}>{div}</div>;
          })}
        </CardContent>
      </Paper>
      <Divider />
    </>
  );
};

export default Messages;
