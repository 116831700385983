import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  makeStyles,
  TextField,
  Typography,
  Grid
} from '@material-ui/core';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import config from '../../config';
import Page from '../../components/Page';
import API from '../../services/api';
import AuthService from '../../auth/auth.service';
import formHelper from '../../utils/formHelper';
import PasswordValidationSection from './PasswordValidationSection';
import getQueryParams from '../../utils/getQueryParams';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  gridLeft: {
    textAlign: 'right'
  }
}));

const ChangePasswordView = props => {
  const classes = useStyles();
  const b2bValue = getQueryParams('b2b');
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [formData, setFormdata] = useState(
    formHelper.simpleData2FormData({
      newPassword: {
        required: true
      }
    })
  );
  const history = useHistory();

  useEffect(() => {
    setUserName(props?.location?.state?.state?.username || '');
    setPassword(props?.location?.state?.state?.password || '');
    // eslint-disable-next-line
  }, []);

  const passwordIsOK = () => {
    const re = new RegExp(
      '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+]).{8,}$'
    );
    if (!re.test(formData.newPassword.value)) {
      enqueueSnackbar(
        'Senha inválida. É necessário ao menos 8 digitos e incluir letras minúsculas, letras maiúsculas, números e caracteres especiais',
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        }
      );
      formHelper.setFormError(formData, setFormdata, 'newPassword');
      return false;
    }
    if (formData.newPassword.value !== confirmNewPassword) {
      enqueueSnackbar('Senhas digitadas estão diferentes, verifique', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      formHelper.setFormError(formData, setFormdata, 'newPassword');
      return false;
    }
    return true;
  };

  const handleSuccess = data => {
    sessionStorage.removeItem('session');
    AuthService.setAccessTokens(data);
    history.push('/');
  };

  const handleSubmit = async () => {
    if (!passwordIsOK()) {
      return;
    }
    const session = sessionStorage.getItem('session');

    try {
      const { data } = await API.post(
        `${config.cognito.authUrl}/customerguide-first-login`,
        {
          username,
          newPassword: formData.newPassword.value,
          session
        }
      );
      handleSuccess(data);
    } catch (error) {
      enqueueSnackbar('Erro', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
    }
  };

  return (
    <Page className={classes.root}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box mb={3}>
            <Card>
              <CardContent>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" flexDirection="row">
                    <Box>
                      <Typography color="textPrimary" variant="h2">
                        Nova Senha
                      </Typography>
                    </Box>
                  </Box>
                  <Box paddingTop={2}>
                    <Typography variant="subtitle1">
                      É necessário criar uma senha definitiva.
                    </Typography>
                  </Box>
                  <Box paddingTop={2}>
                    <TextField
                      label="Usuário"
                      variant="outlined"
                      fullWidth
                      value={username}
                      disabled={username}
                      onChange={ev => {
                        setUserName(ev.target.value);
                      }}
                    />
                  </Box>
                  <Box paddingTop={2}>
                    <TextField
                      label="Senha temporária"
                      type="password"
                      variant="outlined"
                      fullWidth
                      value={password}
                      disabled={password}
                      onChange={ev => {
                        setPassword(ev.target.value);
                      }}
                    />
                  </Box>
                  <Box paddingTop={2}>
                    <TextField
                      label="Nova senha"
                      // helperText="A senha deve conter caracter minúsculo, maiúsculo, números, caracteres especiais e no mínimo 8 dígitos"
                      type="password"
                      variant="outlined"
                      fullWidth
                      onChange={htmlElemtEvent => {
                        formHelper.setFormvalue(
                          formData,
                          setFormdata,
                          'newPassword',
                          htmlElemtEvent.target.value
                        );
                        setNewPassword(htmlElemtEvent.target.value);
                      }}
                    />
                  </Box>
                  <PasswordValidationSection password={newPassword} />
                  <Box paddingTop={2}>
                    <TextField
                      label="Confirme a nova senha"
                      type="password"
                      variant="outlined"
                      fullWidth
                      value={confirmNewPassword}
                      onChange={ev => {
                        setConfirmNewPassword(ev.target.value);
                      }}
                    />
                  </Box>
                  <Grid container justify="space-between">
                    <Grid item lg>
                      <Box paddingTop={2}>
                        <Button
                          variant="contained"
                          color="default"
                          component={Link}
                          to={b2bValue ? '/?b2b=true' : '/'}
                        >
                          Cancelar
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item lg className={classes.gridLeft}>
                      <Box paddingTop={2}>
                        <Button
                          disabled={
                            !formData.newPassword.value || !confirmNewPassword
                          }
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                        >
                          Alterar senha
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default ChangePasswordView;
