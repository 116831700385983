import React from 'react';
import { SvgIcon } from '@material-ui/core';

const IconReportSearch = props => {
  return (
    <SvgIcon {...props}>
      <path
        d="M17.5 7.75V6.25C17.5 6.1125 17.3875 6 17.25 6H5.25C5.1125 6 5 6.1125 5 6.25V7.75C5 7.8875 5.1125 8 5.25 8H17.25C17.3875 8 17.5 7.8875 17.5 7.75ZM5.25 10.5C5.1125 10.5 5 10.6125 5 10.75V12.25C5 12.3875 5.1125 12.5 5.25 12.5H11C11.1375 12.5 11.25 12.3875 11.25 12.25V10.75C11.25 10.6125 11.1375 10.5 11 10.5H5.25ZM9.75 24.625H2.5V2.625H20V13.375C20 13.5125 20.1125 13.625 20.25 13.625H22C22.1375 13.625 22.25 13.5125 22.25 13.375V1.375C22.25 0.821875 21.8031 0.375 21.25 0.375H1.25C0.696875 0.375 0.25 0.821875 0.25 1.375V25.875C0.25 26.4281 0.696875 26.875 1.25 26.875H9.75C9.8875 26.875 10 26.7625 10 26.625V24.875C10 24.7375 9.8875 24.625 9.75 24.625ZM23.6781 26.2344L20.7625 23.3188C21.4594 22.3969 21.875 21.2469 21.875 20C21.875 16.9625 19.4125 14.5 16.375 14.5C13.3375 14.5 10.875 16.9625 10.875 20C10.875 23.0375 13.3375 25.5 16.375 25.5C17.4937 25.5 18.5312 25.1656 19.4 24.5938L22.3594 27.5531C22.4094 27.6031 22.4719 27.625 22.5344 27.625C22.5969 27.625 22.6625 27.6 22.7094 27.5531L23.6781 26.5844C23.7012 26.5614 23.7195 26.5342 23.732 26.5041C23.7444 26.4741 23.7509 26.4419 23.7509 26.4094C23.7509 26.3769 23.7444 26.3446 23.732 26.3146C23.7195 26.2846 23.7012 26.2573 23.6781 26.2344ZM16.375 23.5C14.4406 23.5 12.875 21.9344 12.875 20C12.875 18.0656 14.4406 16.5 16.375 16.5C18.3094 16.5 19.875 18.0656 19.875 20C19.875 21.9344 18.3094 23.5 16.375 23.5Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default IconReportSearch;
