import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import DashboardLayout from '../../layouts/DashboardLayout';
import AuthService from '../../auth/auth.service';

const DashboardPrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={props =>
        AuthService.getAccessToken() ? (
          <DashboardLayout>
            <Component {...props} key={Date.now()} />
          </DashboardLayout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                link: location.pathname
              }
            }}
          />
        )
      }
    />
  );
};

export default DashboardPrivateRoute;
