import axios from 'axios';
import config from '../config';

const API = axios.create({
  baseURL: config.backend.url
});

const AUTHORIZATION_API = axios.create({
  baseURL: config.backend.url
});

const NO_AUTH_TICKET_EVALUATION = axios.create({
  baseURL: config.backend.NewTicketUrl
});

export { AUTHORIZATION_API, NO_AUTH_TICKET_EVALUATION };
export default API;
