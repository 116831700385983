import { CardContent, Grid, Typography } from '@material-ui/core';
import React from 'react';
import AcerModal from '../../components/AcerModal';

export default function ConfirmLoginDialog({
  openState,
  closeModal,
  handleConfirm,
  waiting = false
}) {
  return (
    <AcerModal
      hasAction
      fullWidth
      maxWidth="sm"
      open={openState}
      modalTitle="Criar nova conta"
      onClose={closeModal}
      cancelActionLabel="Já tenho conta"
      confirmActionLabel="Quero criar uma conta nova"
      confirmActionFunc={() => {
        handleConfirm();
      }}
    >
      <Grid
        container
        spacing={2}
        style={{ maxHeight: '380px', overflowY: 'auto', overFlowX: 'hidden' }}
      >
        <CardContent>
          <Typography>
            {waiting ? (
              'Aguarde, direcionando...'
            ) : (
              <>
                Você será direcionado à Loja da Acer para criar sua conta. Caso
                já possua uma conta lá, clique em <b>Já tenho conta</b> e
                utilize os mesmos dados para entrar no ambiente Fale Conosco.
              </>
            )}
          </Typography>
        </CardContent>
      </Grid>
    </AcerModal>
  );
}
