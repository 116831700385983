import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS } from '../../../styles/settings/colors.config';
import './customDrawerStyles.css';

const AcerNavbar = styled(List)`
  background: ${COLORS.whiteText};
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  flex-flow: row nowrap;
  height: 53px;
  padding: 0;
`;

const StyledNavLink = styled(NavLink)`
  align-items: center;
  color: ${COLORS.blackText};
  display: flex;
  flex-flow: row nowrap;
  padding: 26px;
  width: auto;
  &:active {
    color: ${COLORS.blackText};
  }
  &:hover {
    background-color: ${COLORS.lightGray};
  }
  &.is-active {
    background-color: ${COLORS.lightGray};
    color: ${COLORS.greenText};
  }
`;

const NavbarLinks = ({ menuItems }) => {
  return (
    <AcerNavbar>
      {menuItems.map(object => {
        return (
          <StyledNavLink
            exact
            key={object.id}
            to={object.path}
            activeClassName="is-active"
          >
            <ListItemText primary={object.text} />
          </StyledNavLink>
        );
      })}
    </AcerNavbar>
  );
};

export default NavbarLinks;
