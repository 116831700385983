import { Box, Divider } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import React from 'react';
import Footer from './Footer';
import Header from './Header';

export default function LayoutContainer({ children }) {
  return (
    <>
      <Container maxWidth="xl">
        <Header />
      </Container>
      <Box mb={4}>
        <Divider />
      </Box>
      <Container maxWidth="xl">{children}</Container>
      <Footer />
    </>
  );
}
