import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import GlobalStyles from './components/GlobalStyles';
import LoginView from './views/auth/LoginView';
import ReceiveGoogleToken from './views/auth/ReceiveGoogleToken';
import MainLayoutPublicRoute from './utils/routes/mainLayoutPublicRoute';
import DashboardPrivateRoute from './utils/routes/dashboardPrivateRoute';
import DashboardPublicRoute from './utils/routes/dashboardPublicRoute';
import HttpInterceptor from './auth/HttpInterceptor';
import NotFoundView from './views/errors/NotFoundView';
import NewTicket from './views/ticket/NewTicket';
import TicketsListView from './views/ticket/TicketsListView';
import TicketContainer from './views/ticket/TicketContainer';
import PrivacyPolicyView from './views/PrivacyPolicyView';
import DataDeletionLGPDView from './views/DataDeletionLGPDView';
import TicketEvaluation from './views/ticket/Evaluation/TicketEvaluation';
import ChangePasswordView from './views/auth/ChangePasswordView';
import { store } from './store/store';
import VtexAuthMiddleware from './auth/VtexAuthMiddleware';

const App = () => {
  return (
    <Provider store={store}>
      <HttpInterceptor>
        <GlobalStyles />
        <VtexAuthMiddleware>
          <Switch>
            <MainLayoutPublicRoute
              noWrap
              component={ReceiveGoogleToken}
              path="/receiveAuthInfo"
              exact
            />
            <MainLayoutPublicRoute
              noWrap
              component={LoginView}
              path="/"
              exact
            />
            <MainLayoutPublicRoute
              noWrap
              component={LoginView}
              path="/login"
              exact
            />
            <MainLayoutPublicRoute
              noWrap
              component={ChangePasswordView}
              path="/change-password"
              exact
            />
            <MainLayoutPublicRoute
              component={NewTicket}
              path="/nova-solicitacao"
              exact
            />
            <MainLayoutPublicRoute
              component={PrivacyPolicyView}
              path="/privacyPolicies"
              exact
            />
            <MainLayoutPublicRoute
              component={DataDeletionLGPDView}
              path="/dataExclusion"
              exact
            />
            <DashboardPrivateRoute
              component={TicketsListView}
              path="/tickets"
              exact
            />
            <DashboardPrivateRoute
              component={TicketContainer}
              path="/ticket/:id"
              exact
            />
            <DashboardPublicRoute
              component={TicketEvaluation}
              path="/review/:id"
              exact
            />
            <Route component={NotFoundView} />
          </Switch>
        </VtexAuthMiddleware>
      </HttpInterceptor>
    </Provider>
  );
};

export default App;
