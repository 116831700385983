import { createTheme, colors, responsiveFontSizes } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      background: {
        dark: '#F4F6F8',
        default: colors.common.white,
        paper: colors.common.white
      },
      primary: {
        // main: colors.green[600],
        main: '#80c343',
        contrastText: '#fff'
      },
      secondary: {
        main: colors.green[600]
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      }
    },
    shadows,
    typography,
    breakpoints: {
      values: {
        xs: 0,
        mobile: 321,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920
      }
    }
  })
);

export default theme;
