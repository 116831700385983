import { Chip } from '@material-ui/core';
import React from 'react';
import ticketStatusEnum from '../../../utils/ticketStatus.enum';

const TicketStatusChip = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <Chip
      variant="outlined"
      style={{ backgroundColor: '#444', color: '#FFF' }}
      size="medium"
      label={ticketStatusEnum[data]}
    />
    // <>
    //   {ticketStatus.data === 'RESOLVED' || ticketStatus.data === 'CLOSED' ? (
    //     <Chip
    //       variant="outlined"
    //       style={{ backgroundColor: '#444', color: '#FFF' }}
    //       size="medium"
    //       label="Resolvido"
    //     />
    //   ) : (
    //     <Chip
    //       variant="outlined"
    //       style={{ backgroundColor: '#EEE', color: '#000' }}
    //       size="medium"
    //       label="Aberto"
    //     />
    //   )}
    // </>
  );
};

export default TicketStatusChip;
