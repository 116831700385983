import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useHistory } from 'react-router';
import API from '../services/api';
import AuthService from './auth.service';

let isRefreshing = false;
let refreshSubscribers = [];

const onTokenRefreshed = newToken => {
  refreshSubscribers.forEach(callback => callback(newToken));
  refreshSubscribers = [];
};

const HttpInterceptor = ({ children }) => {
  const snack = useState(useSnackbar());
  const redirect = useState(useHistory());

  API.interceptors.request.use(request => {
    const token = AuthService.getIdToken();
    if (token) {
      request.headers.Authorization = token;
    }

    return request;
  });

  API.interceptors.response.use(
    response => response,
    async error => {
      if (error.response && error.response.status === 401) {
        if (isRefreshing) {
          return new Promise(
            (resolve => {
              refreshSubscribers.push(resolve);
            }).then(newToken => {
              const originalRequest = { ...error.config };
              originalRequest.headers.Authorization = newToken;
              return axios(originalRequest);
            })
          );
        }

        isRefreshing = true;

        try {
          const response = await AuthService.getNewTokenFromAPI();
          localStorage.setItem('idToken', response.data.idToken);
          localStorage.setItem('accessToken', response.data.accesToken);

          isRefreshing = false;
          const newToken = localStorage.getItem('idToken');

          if (newToken) {
            onTokenRefreshed(newToken);
            const originalRequest = { ...error.config };
            originalRequest.headers.Authorization = newToken;
            return axios(originalRequest);
          }
        } catch (refreshError) {
          isRefreshing = false;
          AuthService.logout(redirect);
          snack[0].enqueueSnackbar('Não autorizado!', { variant: 'error' });
        }
      }
    }
  );
  return children;
};

export default HttpInterceptor;
