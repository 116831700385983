import { CircularProgress } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

export const StyledLoader = styled.div`
  align-items: center;
  background: rgb(0 0 0 / 0.3);
  display: flex;
  flex-flow: row wrap;
  height: calc(100vh + 110px);
  justify-content: center;
  left: -59px;
  position: fixed;
  top: -64px;
  width: calc(100vw + 59px);
  z-index: 9999;
`;

const Loader = () => {
  return (
    <StyledLoader>
      <CircularProgress />
    </StyledLoader>
  );
};

export default Loader;
