import React from 'react';

const UploadIcon = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 9.9281H15.3094V20.5C15.3094 20.6375 15.4219 20.75 15.5594 20.75H17.4344C17.5719 20.75 17.6844 20.6375 17.6844 20.5V9.9281H20C20.2094 9.9281 20.325 9.68747 20.1969 9.52497L16.6969 5.09372C16.6735 5.06384 16.6436 5.03968 16.6095 5.02306C16.5754 5.00644 16.5379 4.9978 16.5 4.9978C16.4621 4.9978 16.4246 5.00644 16.3905 5.02306C16.3564 5.03968 16.3265 5.06384 16.3031 5.09372L12.8031 9.52185C12.675 9.68748 12.7906 9.9281 13 9.9281ZM27.9375 19.5625H26.0625C25.925 19.5625 25.8125 19.675 25.8125 19.8125V24.625H7.1875V19.8125C7.1875 19.675 7.075 19.5625 6.9375 19.5625H5.0625C4.925 19.5625 4.8125 19.675 4.8125 19.8125V26C4.8125 26.5531 5.25937 27 5.8125 27H27.1875C27.7406 27 28.1875 26.5531 28.1875 26V19.8125C28.1875 19.675 28.075 19.5625 27.9375 19.5625Z"
        fill="#80C343"
      />
    </svg>
  );
};

export default UploadIcon;
