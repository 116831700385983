import jwt_decode from 'jwt-decode';
import React from 'react';
import { useHistory } from 'react-router';
import SetCpfView from './SetCpf';

const ReceiveGoogleToken = () => {
  const history = useHistory();
  const params = new URLSearchParams(
    window.location.href.replace('receiveAccessCode#', 'receiveAccessCode?')
  );
  const encodedIdToken = params.get('id_token');
  const idToken = jwt_decode(encodedIdToken);

  localStorage.setItem('idToken', encodedIdToken);
  localStorage.setItem('accessToken', encodedIdToken);

  const cpf = idToken?.['custom:cpf'];
  const userId = idToken?.['cognito:username'];
  if (!cpf) {
    return <SetCpfView userId={userId} />;
  }

  history.push('/tickets');
  return <></>;
};

export default ReceiveGoogleToken;
