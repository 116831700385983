const getQueryParams = param => {
  // Obtém a string da URL
  const url = new URL(window.location.href);

  // Obtém os parâmetros da URL
  const params = new URLSearchParams(url.search);

  // Obtém o valor do parâmetro "b2b"
  const b2bValue = params.get(param);

  return b2bValue;
};

export default getQueryParams;
