import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  Fade,
  Grid,
  makeStyles,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import React, { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import API, { NO_AUTH_TICKET_EVALUATION } from '../../../services/api';

const useStyles = makeStyles(() => ({
  thumbsUpStyle: {
    color: '#00960a'
  },
  thumbsDownStyle: {
    color: '#b80012'
  },
  disabledChipStyle: {
    opacity: '1',
    backgroundColor: '#b5b5b5'
  },
  clickedChipStyle: {
    '&:hover': {
      backgroundColor: '#b5b5b5'
    },
    '&:focus': {
      backgroundColor: '#b5b5b5'
    },
    '&%:disabled': {
      opacity: '1',
      backgroundColor: '#b5b5b5'
    }
  }
}));

const SatisfactionScore = ({ data, token }) => {
  const classes = useStyles();
  const [newReview, setNewReview] = useState(['', null]);
  const [sentEvaluation, setSentEvaluation] = useState(false);
  const [newEvaluation, setNewEvaluation] = useState(false);

  const thumbsUpClick = () => {
    if (newReview[1] === null) {
      setNewReview([newReview[0], true]);
    } else {
      setNewReview([newReview[0], null]);
    }
  };
  const thumbsDownClick = () => {
    if (newReview[1] === null) {
      setNewReview([newReview[0], false]);
    } else {
      setNewReview([newReview[0], null]);
    }
  };

  const evaluationClick = () => {
    setNewEvaluation(!newEvaluation);
  };

  const isomorphicApi = token ? NO_AUTH_TICKET_EVALUATION : API;

  const endpoint = () => {
    if (token) {
      return '/review';
    }
    return '/tickets/send-satisfaction-review-from-fale-conosco';
  };

  const sendNewOpinion = () => {
    const postPayload = {
      ticketId: data.id,
      token: token || null,
      satisfactionScore: newReview[1],
      opinion: newReview[0]
    };

    isomorphicApi
      .post(endpoint(), postPayload)
      .then(() => {
        enqueueSnackbar('Sua avaliação foi enviada!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        });
        setSentEvaluation(true);
      })
      .catch(() => {
        enqueueSnackbar('Não foi possível enviar sua avaliação.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        });
        setSentEvaluation(true);
      });
  };

  const returnValidInfo = () => {
    if (
      data.marketplaceBundle.customerGuideServiceOpinionList.length > 0 &&
      !newEvaluation
    ) {
      return (
        <>
          <Typography justify="center">
            {' '}
            Sua avaliação para esse atendimento:
          </Typography>
          <br />
          <Grid container justify="center" spacing={4}>
            <Grid item xs={4}>
              <TextField
                size="small"
                fullWidth
                multiline
                minRows={1}
                maxRows={3}
                value={
                  data.marketplaceBundle.customerGuideServiceOpinionList[0]
                    .opinion
                }
                disabled
                variant="outlined"
              />
            </Grid>
            {data.marketplaceBundle.customerGuideServiceOpinionList[0]
              .satisfactionScore ? (
              <Grid item>
                <Chip
                  className={classes.disabledChipStyle}
                  icon={<ThumbUpIcon className={classes.thumbsUpStyle} />}
                  label="Gostei desse atendimento!"
                  disabled
                />
              </Grid>
            ) : (
              <Grid item>
                <Chip
                  className={classes.disabledChipStyle}
                  icon={<ThumbDownIcon className={classes.thumbsDownStyle} />}
                  label="Não gostei desse atendimento!"
                  disabled
                />
              </Grid>
            )}
          </Grid>
        </>
      );
    }
    if (data.ticketStatus === 'CLOSED') {
      return (
        <Typography justify="center">
          {' '}
          Não é mais possível avaliar esse atendimento
        </Typography>
      );
    }
    if (!newEvaluation) {
      return (
        <Typography justify="center">
          {' '}
          Ainda não foi feita uma avaliação
        </Typography>
      );
    }
  };
  return (
    <>
      {data.ticketStatus === 'CLOSED' || data.ticketStatus === 'RESOLVED' ? ( // Trocar para == RESOLVED E CLOSED
        <div>
          <br />
          <Card>
            <CardContent>
              <br />

              {returnValidInfo()}

              {data.ticketStatus === 'RESOLVED' && !sentEvaluation ? ( // Trocar para == RESOLVED
                <div>
                  <Grid container justify="center">
                    {!newEvaluation && (
                      <Typography>
                        <br />
                        {!data.marketplaceBundle.customerGuideServiceOpinionList
                          .length > 0 ? (
                          <Button
                            id="EvaluationButton"
                            size="small"
                            variant="contained"
                            onClick={evaluationClick}
                            color="primary"
                          >
                            {' '}
                            Clique aqui para realizar uma avaliação
                          </Button>
                        ) : (
                          <Button
                            id="EvaluationButton"
                            size="small"
                            variant="contained"
                            onClick={evaluationClick}
                            color="primary"
                          >
                            {' '}
                            Clique aqui para realizar uma nova avaliação
                          </Button>
                        )}
                      </Typography>
                    )}
                  </Grid>
                  {newEvaluation ? (
                    <div>
                      <Typography justify="center">
                        {' '}
                        Oque achou desse atendimento?
                      </Typography>
                      <Typography>
                        <br />
                        <Grid container justify="center" spacing={10}>
                          {newReview[1] !== false ? (
                            <Grid item>
                              <Chip
                                clickable
                                className={
                                  newReview[0] ? null : classes.clickedChipStyle
                                }
                                icon={
                                  <ThumbUpIcon
                                    className={classes.thumbsUpStyle}
                                  />
                                }
                                label="Bom, estou contente!"
                                onClick={thumbsUpClick}
                              />
                            </Grid>
                          ) : null}
                          {newReview[1] !== true ? (
                            <Grid item>
                              <Chip
                                clickable
                                icon={
                                  <ThumbDownIcon
                                    className={classes.thumbsDownStyle}
                                  />
                                }
                                label="Ruim, estou descontente!"
                                onClick={thumbsDownClick}
                              />
                            </Grid>
                          ) : null}
                        </Grid>
                      </Typography>
                    </div>
                  ) : null}
                  {newReview[1] != null && newEvaluation ? (
                    <Fade in={newReview[1] != null && newEvaluation}>
                      <Typography variant="overline">
                        <Grid container justify="center" spacing={3}>
                          <Grid item xs={5}>
                            <TextField
                              size="small"
                              value={newReview[0]}
                              fullWidth
                              multiline
                              minRows={2}
                              maxRows={3}
                              onChange={htmlElemtEvent => {
                                setNewReview([
                                  htmlElemtEvent.target.value,
                                  newReview[1]
                                ]);
                              }}
                              placeholder="Deixe uma opinião"
                              variant="outlined"
                              inputProps={{ maxLength: '500' }}
                              helperText={`${newReview[0].length}/500`}
                            />
                          </Grid>
                        </Grid>
                        <Typography variant="overline">
                          <Grid container justify="center" spacing={3}>
                            <Grid item>
                              <ButtonGroup
                                fullWidth={false}
                                disableElevation
                                variant="contained"
                                style={{ backgroundColor: '#535454' }}
                              >
                                <Tooltip title="Enviar Opinião">
                                  <Button
                                    onClick={() => {
                                      sendNewOpinion();
                                    }}
                                    variant="contained"
                                    color="primary"
                                    endIcon={<SendIcon />}
                                  >
                                    {' '}
                                    Enviar{' '}
                                  </Button>
                                </Tooltip>
                              </ButtonGroup>
                            </Grid>
                          </Grid>
                        </Typography>
                      </Typography>
                    </Fade>
                  ) : null}
                </div>
              ) : (
                <>
                  {sentEvaluation ? (
                    <div>
                      <Typography justify="center">
                        Obrigado por sua avaliação
                      </Typography>
                      <Typography variant="overline" disableSpacing>
                        <Grid container justify="center" spacing={4}>
                          <Grid item xs={4}>
                            <TextField
                              fullWidth
                              multiline
                              size="small"
                              minRows={1}
                              maxRows={3}
                              value={newReview[0]}
                              disabled
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item>
                            {newReview[1] ? (
                              <Chip
                                className={classes.disabledChipStyle}
                                icon={
                                  <ThumbUpIcon
                                    className={classes.thumbsUpStyle}
                                  />
                                }
                                label="Gostei desse atendimento!"
                                disabled
                              />
                            ) : (
                              <Chip
                                className={classes.disabledChipStyle}
                                icon={
                                  <ThumbDownIcon
                                    className={classes.thumbsDownStyle}
                                  />
                                }
                                label="Não gostei desse atendimento!"
                                disabled
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Typography>
                    </div>
                  ) : null}
                </>
              )}
            </CardContent>
          </Card>
        </div>
      ) : null}
      <br />
    </>
  );
};

export default SatisfactionScore;
