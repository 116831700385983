import {
  Box,
  Breadcrumbs,
  Card,
  Container,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Page from '../../../components/Page';
import { NO_AUTH_TICKET_EVALUATION } from '../../../services/api';
import Messages from '../messages/messages';
import SatisfactionScore from '../Satisfaction/SatisfactionScore';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const TicketEvaluation = ({ match }) => {
  const classes = useStyles();
  const ticketId = match.params.id;
  const [ticketData, setTicketData] = useState({
    marketplaceGenericMessages: []
  });

  const params = window.location;
  const token = params.href.split('?')[1]?.split('=')[1];

  const updateChat = () => {
    NO_AUTH_TICKET_EVALUATION.get(`/review/${ticketId}`).then(response => {
      const newState = { ...ticketData, ...response.data };
      setTicketData(newState);
    });
  };

  useEffect(() => {
    updateChat();
    // eslint-disable-next-line
  }, []);

  return (
    <Page className={classes.root} title="Ticket Evaluation">
      <Container maxWidth={false}>
        <Breadcrumbs gutterBottom aria-label="breadcrumb">
          <Link color="inherit" to="/tickets">
            Meus Tickets
          </Link>
        </Breadcrumbs>
        <Grid container>
          <Grid item lg={3}>
            <Typography variant="overline">Ticket </Typography>
            <Typography color="textPrimary">{ticketId}</Typography>
          </Grid>
          <Grid item lg={3}>
            <Typography variant="overline"> Atendente</Typography>
            <Typography>{ticketData.serviceAttendantLogin}</Typography>
          </Grid>
          <Grid item lg={3}>
            <Typography variant="overline"> Motivo:</Typography>
            <Typography>{ticketData.subject}</Typography>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} xs={12}>
              <SatisfactionScore data={ticketData} token={token} />
              <Card>
                <Messages chat={ticketData.marketplaceGenericMessages} />
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default TicketEvaluation;
