/* eslint-disable react/no-array-index-key */
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import React from 'react';
import timezoneFormat from '../../../utils/timezoneFormat';
import API from '../../../services/api';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#e3eeda'
  }
}));

const ClientMessageCard = ({ message, customerName }) => {
  const classes = useStyles();

  const getFile = (filename, key) => {
    API.get(`/files/${key}`).then(response => {
      const link = document.createElement('a');
      link.href = `data:image/png;base64,${response.data.content}`;
      link.download = filename;
      link.click();
    });
  };

  const getMultilineMessage = msg => {
    const splittedMessage = String(msg).split('\n');
    return splittedMessage.map((item, index) => (
      <Typography
        key={index}
        variant="body1"
        style={{ whiteSpace: 'pre-line' }}
      >
        {item}
      </Typography>
    ));
  };

  const getClientName = () => {
    const firstCustomerName = customerName ? customerName.split(' ')[0] : null;
    let name = firstCustomerName || message.agent;
    if (name === 'CLIENT') name = 'Cliente';

    return name;
  };

  return (
    <Card>
      <CardContent className={classes.root}>
        {getMultilineMessage(message.message)}
        <Grid container>
          <Grid item lg={8}>
            <Typography
              color="textSecondary"
              align="left"
              variant="caption"
              component="h2"
            >
              {timezoneFormat(message.date)}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography
              color="textSecondary"
              align="right"
              variant="caption"
              component="h2"
            >
              {getClientName()}
            </Typography>
          </Grid>
        </Grid>
        {message.attachments ? (
          message.attachments.map((m, index) => {
            return (
              <span key={index}>
                <Button
                  onClick={() => {
                    getFile(m.name, m.key);
                  }}
                >
                  {m.name}
                </Button>
                <br />
                <Divider />
              </span>
            );
          })
        ) : (
          <></>
        )}
      </CardContent>
    </Card>
  );
};

export default ClientMessageCard;
