/* eslint-disable react/no-array-index-key */
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  LinearProgress,
  makeStyles,
  Typography
} from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import timezoneFormat from '../../../utils/timezoneFormat';
import API from '../../../services/api';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#ffffff'
  }
}));

const AgentMessageCard = ({ message }) => {
  const classes = useStyles();

  const { nicknames } = useSelector(state => state.nicknames);

  const findNickname = search => {
    const findedNickname = nicknames?.find(
      loadedNickname => loadedNickname.username === search
    );

    if (findedNickname?.nickname) {
      return findedNickname.nickname;
    }

    return search;
  };

  const getFile = (filename, key) => {
    API.get(`/files/${key}`).then(response => {
      const link = document.createElement('a');
      link.href = `data:image/png;base64,${response.data.content}`;
      link.download = filename;
      link.click();
    });
  };

  const getMultilineMessage = msg => {
    const splittedMessage = String(msg).split('\n');
    return splittedMessage.map((item, index) => (
      <Typography
        key={index}
        variant="body1"
        style={{ whiteSpace: 'pre-line' }}
      >
        {item}
      </Typography>
    ));
  };

  const getAgentName = () => {
    let name = message.username ? message.username : message.agent;
    if (name === 'ABO') name = 'Atendente';

    return name;
  };

  return (
    <Card>
      <CardContent className={classes.root}>
        {getMultilineMessage(message.message)}
        <Grid container>
          <Grid item lg={8}>
            <Typography
              color="textSecondary"
              align="left"
              variant="caption"
              component="h2"
            >
              {timezoneFormat(message.date)}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography
              color="textSecondary"
              align="right"
              variant="caption"
              component="h2"
            >
              {findNickname(getAgentName())}
            </Typography>
          </Grid>
        </Grid>
        {message.status && <LinearProgress />}
        {message.attachments ? (
          message.attachments.map((m, index) => {
            return (
              <span key={index}>
                <Button
                  onClick={() => {
                    getFile(m.name, m.key);
                  }}
                >
                  {m.name}
                </Button>
                <br />
                <Divider />
              </span>
            );
          })
        ) : (
          <></>
        )}
      </CardContent>
    </Card>
  );
};

export default AgentMessageCard;
