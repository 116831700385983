import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import * as serviceWorker from './serviceWorker';
import theme from './theme';
import App from './App';
import config from './config';

ReactDOM.render(
  <GoogleReCaptchaProvider reCaptchaKey={config.recaptchaSiteKey}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3} style={{ fontFamily: 'Roboto' }} />
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </GoogleReCaptchaProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
