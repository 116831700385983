import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Box, Divider } from '@material-ui/core';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Acer {new Date().getFullYear()} -{' '}
      <Link
        href="https://www.acer.com/ac/pt/BR/content/privacy"
        target="_blank"
        rel="noreferrer"
        id="link_to_acer_conecta_politica"
      >
        Políticas de privacidade
      </Link>
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 0)
  }
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <>
      <Box mt={3}>
        <Divider />
      </Box>
      <footer className={classes.footer}>
        <Container maxWidth="lg">
          <Typography
            variant="subtitle1"
            align="center"
            color="textSecondary"
            component="p"
          />
          <Copyright />
        </Container>
      </footer>
    </>
  );
}
