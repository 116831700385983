import { IconButton, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLORS } from '../styles/settings/colors.config';

const AcerModalStyled = styled(Dialog)`
  .acer-modal-header {
    align-items: center;
    background-color: ${COLORS.greenText};
    color: ${COLORS.whiteText};
    display: flex;
    justify-content: space-between;
    padding: 4px 12px;

    button {
      bottom: 6px;
      span {
        color: ${COLORS.whiteText};
      }
    }
  }
`;

/**
 * This component is a styled modal for Acer project, according to UX style.
 * @param {Boolean} fullWidth - Boolean - If is full width or not
 * @param {String} maxWidth - String - 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
 * @param {Boolean} open - Boolean - Open controller
 * @param {String} modalTitle - String - Title displayed in modal
 * @param {Boolean} hasAction - Boolean - Show footer button if true
 * @param {String} confirmActionLabel - String - Confirm action label displayed in button
 * @param {Function} confirmActionFunc - Function - Confirm action function
 * @param {String} cancelActionLabel - String - Cancel action label displayed in button
 * @param {Function} onClose - Function - Cancel action function
 * @param {String} classes - String - Optional CSS class
 * @param {JSXAttribute} children - JSXAttribute - Content in the modal
 * @param {String} scroll - default "paper" show scroll bar if the element inside demands it, value "body" hides de scroll bar
 */
export default function AcerModal({
  fullWidth,
  maxWidth,
  open,
  modalTitle,
  hasAction,
  confirmActionLabel,
  confirmActionFunc,
  cancelActionLabel,
  onClose,
  classes,
  children,
  freeBody,
  scroll = 'paper'
}) {
  return (
    <AcerModalStyled
      fullWidth={fullWidth}
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      classes={classes}
      scroll={scroll}
    >
      <DialogTitle
        className="acer-modal-header"
        disableTypography
        onClose={onClose}
      >
        <Typography variant="h4">{modalTitle}</Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {!freeBody && <DialogContent dividers>{children}</DialogContent>}
      {freeBody && <>{children}</>}

      {hasAction && (
        <DialogActions>
          {cancelActionLabel && (
            <Button onClick={onClose} color="primary">
              {cancelActionLabel}
            </Button>
          )}
          <Button
            onClick={confirmActionFunc}
            color="primary"
            autoFocus
            variant="contained"
          >
            {confirmActionLabel}
          </Button>
        </DialogActions>
      )}
    </AcerModalStyled>
  );
}

AcerModal.propTypes = {
  cancelActionLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line
  classes: PropTypes.any,
  confirmActionLabel: PropTypes.string,
  confirmActionFunc: PropTypes.func,
  freeBody: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hasAction: PropTypes.bool,
  maxWidth: PropTypes.string,
  modalTitle: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

AcerModal.defaultProps = {
  cancelActionLabel: '',
  confirmActionLabel: '',
  confirmActionFunc: () => {},
  classes: {},
  freeBody: false,
  fullWidth: false,
  hasAction: false,
  maxWidth: 'md'
};
