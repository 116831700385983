import {
  Box,
  Container,
  IconButton,
  makeStyles,
  Tooltip,
  TableCell
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import MUIDataTable from 'mui-datatables';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import getQueryParams from '../../utils/getQueryParams';
import config from '../../config';
import timezoneFormat from '../../utils/timezoneFormat';
import Loader from '../../utils/Loader';
import Page from '../../components/Page';
import API from '../../services/api';
import TicketStatusEnum from '../../utils/ticketStatus.enum';
import { load } from '../../features/nickname/nicknameSlice';

const oldRender = TableCell.render;

// eslint-disable-next-line
TableCell.render = function(...args) {
  const [props, ...otherArgs] = args;
  if (typeof props === 'object' && props && 'isEmpty' in props) {
    const { isEmpty, ...propsWithoutEmpty } = props;
    return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
  }
  return oldRender.apply(this, args);
};

makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const columns = [
  {
    name: 'id',
    label: 'ID',
    options: {
      sort: false
    }
  },
  {
    name: 'subject',
    label: 'Assunto',
    options: {
      filter: true,
      sort: false
    }
  },
  {
    name: 'ticketStatus',
    label: 'Status',
    options: {
      filter: true,
      sort: false,
      customBodyRender: value => TicketStatusEnum[value]
    }
  },
  {
    name: 'creationDate',
    label: 'Data da abertura',
    options: {
      filter: true,
      sort: false,
      customBodyRender: value => {
        return timezoneFormat(value);
      }
    }
  }
];

const decryptNicknames = fetchedNicknames => {
  const encodedKey = CryptoJS.enc.Base64.parse(
    process.env.REACT_APP_AES_ENCRYPT_KEY
  );

  const cipherParams = CryptoJS.lib.CipherParams.create({
    ciphertext: CryptoJS.enc.Base64.parse(fetchedNicknames.data)
  });

  const decryptedData = CryptoJS.AES.decrypt(cipherParams, encodedKey, {
    mode: CryptoJS.mode.ECB
  });

  return JSON.parse(CryptoJS.enc.Utf8.stringify(decryptedData));
};

const TicketsListView = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageData, setPageData] = useState([]);
  const history = useHistory();
  const [loaded, setLoaded] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [sortOrder, setSortOrder] = React.useState('');
  const dispatch = useDispatch();
  const b2bValue = getQueryParams('b2b');

  const { nicknames, storeUpdated } = useSelector(state => state.nicknames);

  const loadNicknames = () => {
    const fetchData = async () => {
      const fetchedNicknames = await axios.get(
        `${config.cognito.authUrl}/find-users-nickname-encrypted`
      );
      const dataLoaded = decryptNicknames(fetchedNicknames);
      dispatch(load(dataLoaded));
    };

    // Date difference
    const diffTime = Math.abs(new Date() - storeUpdated);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    /**
     * if nicknames list is empty or does not exist, fetch data from server and
     * loads this data on store
     */

    const storageNicknames = localStorage.getItem('persist:root');

    if (!storageNicknames && (nicknames?.length === 0 || !nicknames)) {
      fetchData();
    }

    if (storageNicknames && nicknames?.length === 0) {
      fetchData();
    }

    /**
     * if nicknames list exists, checks the date this list was updated. If this
     * updated date is more than 3 days from today, fetch data from server and refresh the store
     */
    if (diffDays > 3) {
      fetchData();
    }
  };

  const loadTableContent = async page => {
    setCurrentPage(page);
    setLoaded(false);

    const queryParams = sortOrder
      ? `&sort=${sortOrder.name},${sortOrder.direction}`
      : ``;
    try {
      const response = await API.get(
        `/tickets/?page=${page}${queryParams}&business_type=${
          b2bValue ? 'B2B' : ''
        }`
      );
      const content = response?.data?._embedded;
      setData(content?.ticketResourceList);
      setPageData(response?.data?.page);
      setLoaded(true);
    } catch (error) {
      setLoaded(true);
      enqueueSnackbar('Não foi possível listar os tickets. Tente novamente', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
    }
  };

  const refreshTable = () => {
    loadTableContent(currentPage);
  };

  useEffect(() => {
    if (!loaded) {
      loadTableContent(currentPage);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadNicknames();
    // eslint-disable-next-line
  }, []);

  const options = {
    download: false,
    filter: false,
    viewColumns: false,
    print: false,
    search: false,
    pagination: true,
    filterType: 'checkbox',
    rowsPerPage: pageData.size,
    count: pageData.totalElements,
    rowsPerPageOptions: [],
    serverSide: true,
    page: currentPage,
    onRowClick: rowData => {
      history.push(`/ticket/${rowData[0]}/${b2bValue ? '?b2b=true' : ''}`);
    },
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        loadTableContent(tableState.page);
        setSortOrder('');
      }
      if (action === 'sort') {
        setSortOrder(tableState.sortOrder);
        loadTableContent(0);
      }
    },
    customToolbar: () => {
      return (
        <Tooltip title="Atualizar">
          <IconButton onClick={() => refreshTable()} aria-label="delete">
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      );
    },
    textLabels: {
      body: {
        noMatch: loaded
          ? 'Nenhum registro localizado'
          : 'Aguarde, carregando informações...',
        toolTip: 'Ordenar',
        colummHeaderTooltip: column => `Ordenar por ${column.label}`
      },
      toolbar: {
        search: 'Procurar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Selecionar colunas',
        filterTable: 'Filtrar'
      },
      viewColumns: {
        title: 'Marque as colunas desejadas',
        titleAria: 'Mostrar/Esconder colunas'
      },
      selectedRows: {
        text: 'linha(s) selecionada(s)',
        delete: 'Apagar',
        deleteAria: 'Apagar linha(s) selecionada(s)'
      },
      pagination: {
        next: 'Próx. pág.',
        previous: 'Pág. anterior',
        rowsPerPage: 'Máximo de linhas p/ pág.',
        displayRows: 'de'
      }
    }
  };

  return (
    <Page title="Tickets">
      <Container maxWidth={false}>
        {loaded ? (
          <Box mt={3}>
            <MUIDataTable
              title="Tickets"
              data={data}
              columns={columns}
              options={options}
              selectableRowsOnClick
            />
          </Box>
        ) : (
          <Loader />
        )}
      </Container>
    </Page>
  );
};

export default TicketsListView;
