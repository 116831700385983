const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validaForm = (formData, setFormdata) => {
  const newState = { ...formData };
  let valid = true;
  Object.entries(newState).forEach(value => {
    const index = value[0];
    const object = value[1];
    let error =
      (!object?.value && object?.required) ||
      (object?.masked && object?.required && object?.value.includes('_')) ||
      (index === 'cpf' &&
        (object.value.length < 11 ||
          object.value.length === 12 ||
          object.value.length === 13));
    if (index === 'email') {
      error = !validateEmail(object.value);
    }
    newState[index].error = error;
    if (error) valid = false;
  });
  setFormdata(newState);
  return valid;
};

const setFormvalue = (formData, setFormdata, key, value) => {
  const newState = { ...formData };
  newState[key].value = value.trim();
  setFormdata(newState);
};

const existsEmptySpaces = field => {
  return field.includes(' ');
};

const telefoneFlex = (
  key,
  formData,
  setFormdata,
  setMascaraTelefone,
  htmlElemtEvent
) => {
  const inputedValue = htmlElemtEvent?.target?.value;
  setFormvalue(formData, setFormdata, key, inputedValue);
  if (formData[key].value.includes('_')) {
    setMascaraTelefone('(99)9999-9999');
    const numberOfUnderlines = (inputedValue.match(/_/g) || []).length;
    let newNumber;
    if (numberOfUnderlines === 1) {
      newNumber = inputedValue.substring(0, inputedValue.length - 1);
    }
    setFormvalue(formData, setFormdata, key, newNumber);
  } else {
    setMascaraTelefone('(99)99999-9999');
  }
};

const formData2PostData = formData => {
  const postData = {};
  Object.entries(formData).forEach(value => {
    const index = value[0];
    const object = value[1];
    postData[index] = object.value;
    if (object.leaveOnlyNumbersWhenSubmit) {
      postData[index] = object.value.replace(/\D/g, '');
    }
  });
  return postData;
};

const simpleData2FormData = formData => {
  Object.entries(formData).forEach(value => {
    const index = value[0];
    formData[index].value = '';
  });
  return formData;
};

const setFormError = (formData, setFormdata, key) => {
  const newErrorFieldFormData = formData[key];
  newErrorFieldFormData.error = true;
  const newFormData = { ...formData, newErrorFieldFormData };
  setFormdata(newFormData);
};

export default {
  validaForm,
  setFormvalue,
  telefoneFlex,
  formData2PostData,
  simpleData2FormData,
  validateEmail,
  setFormError,
  existsEmptySpaces
};
