/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import axios from 'axios';
import config from '../config';

import Loader from '../utils/Loader';

export default function VtexAuthMiddleware({ children }) {
  const history = useHistory();

  const [suspense, setIsSuspense] = useState(() => {
    const params = new URLSearchParams(new URL(window.location.href).search);
    const vtexToken = params.get('vtexToken');
    const orderId = params.get('orderId');

    return !!vtexToken || !!orderId;
  });

  useEffect(() => {
    const params = new URLSearchParams(new URL(window.location.href).search);
    const vtexToken = params.get('vtexToken');

    const orderId = params.get('orderId');

    if (orderId && !vtexToken) {
      history.push(`/nova-solicitacao?orderId=${orderId}`);
      setIsSuspense(false);
      return;
    }

    if (!vtexToken) return;

    axios
      .post(`${config.cognito.authUrl}/login-acer-store`, {
        vtexToken
      })
      .then(({ data }) => {
        if (data.key === 'i18n.login.INTERNAL_ERROR') {
          throw new Error('Unauthorized');
        }

        localStorage.setItem('accessToken', data.accesToken);
        localStorage.setItem('refreshToken', data.refreshToken);
        localStorage.setItem('idToken', data.idToken);

        history.push('/tickets');
      })
      .catch(() => {
        history.push('/login');
      })
      .finally(() => {
        setIsSuspense(false);
      });
  }, [history]);

  if (suspense) return <Loader />;
  return children;
}
