import { Button, Typography } from '@material-ui/core';

import React, { useState } from 'react';

import { useHistory } from 'react-router';
import styled from 'styled-components';
import loginBg from '../../assets/images/login-bg.jpg';
import logoAcer from '../../assets/images/logo-login-082021.png';
import { COLORS } from '../../styles/settings/colors.config';
import ConfirmLoginDialog from './ConfirmLoginDialog';
import Login from './Login';
import getQueryParams from '../../utils/getQueryParams';

const StyledButton = styled(Button)`
  background-color: #43a047 !important;
  &:hover {
    background-color: rgb(46, 112, 49) !important;
  }
`;

const CustomSpanLink = styled.span`
  color: ${COLORS.greenText};
  font-family: 'Roboto';
  font-size: 12px;
  cursor: pointer;
`;

export const LoginContainer = styled.section`
  align-items: center;
  display: flex;
  flex-flow: row-reverse nowrap;
  .login-content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100vh;
    padding: 0 8px;
    width: 100%;
    @media screen and (min-width: 768px) {
      padding: 0 24px;
      width: 50%;
    }
    @media screen and (min-width: 1024px) {
      justify-content: flex-start;
      padding: 48px;
      width: 40%;
    }

    @media screen and (min-width: 1920px) {
      justify-content: flex-start;
      width: 30%;
    }

    &-header {
      align-items: center;
      bottom: 20vh;
      display: flex;
      justify-content: space-between;
      position: relative;
      @media screen and (min-width: 768px) {
        align-items: flex-start;
        position: initial;
      }
      img {
        margin-bottom: 56px;
        width: 250px;
      }
      button {
        position: relative;
        top: 5px;
      }
    }

    &-form {
      width: 100%;
      @media screen and (min-width: 1024px) {
        /* margin-bottom: 35%;
        margin-top: auto;
        position: relative; */
      }

      button {
        height: 45px;
        width: 100%;
      }

      .login-subtitle {
        border-bottom: 1px solid ${COLORS.btnSecondaryColor};
        font-weight: 400;
        line-height: 0.1em;
        margin: 56px 0 16px;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
        span {
          background: #fff;
          color: ${COLORS.btnSecondaryColor};
          font-size: 1rem;
          padding: 20px;
        }
      }
    }
  }

  .login-background {
    display: none;
    @media screen and (min-width: 768px) {
      background: #4caf50;
      background-image: url(${loginBg});
      background-position: center;
      background-size: cover;
      display: block;
      height: 100vh;
      width: 50%;
    }
    @media screen and (min-width: 1024px) {
      width: 60%;
    }

    @media screen and (min-width: 1920px) {
      width: 70%;
    }

    &::after {
      display: none;
      @media screen and (min-width: 768px) {
        background: linear-gradient(to right, #ffffff70, #80c343ad);
        content: ' ';
        display: block;
        height: 100%;
        position: absolute;
        width: 50%;
      }
      @media screen and (min-width: 1024px) {
        width: 60%;
      }
      @media screen and (min-width: 1920px) {
        width: 70%;
      }
    }
  }

  .social-btn {
    display: flex;
    justify-content: center;
    margin: 16px 0;
    width: 100%;

    button {
      text-transform: inherit;
      width: 100%;
      &.btn-google {
        background-color: #fff;
        color: #424242;
      }
      &.btn-facebook {
        background-color: #395697;
      }
      img {
        bottom: 3px;
        margin-right: 8px;
        position: relative;
        width: 20px;
      }
    }
  }
`;

const LoginView = ({ onlyLogin }) => {
  const history = useHistory();

  const [confirmModalOpened, setConfirmModalOpened] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const b2bValue = getQueryParams('b2b');

  const handleConfirm = () => {
    setWaiting(true);
    setTimeout(() => {
      if (b2bValue) {
        window.location = 'http://acerempresas.com.br/register';
      } else {
        window.location =
          'https://br-store.acer.com/login?returnUrl=%2Faccount';
      }
    }, 2000);
  };

  if (onlyLogin) {
    return (
      <LoginContainer>
        <Login />
      </LoginContainer>
    );
  }
  return (
    <>
      <LoginContainer>
        <div className="login-content">
          <div
            className="login-content-header"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '56px'
            }}
          >
            <img
              src={logoAcer}
              alt="Logo oficial da Acer"
              style={{ margin: '0px' }}
            />

            <CustomSpanLink onClick={() => setConfirmModalOpened(true)} sty>
              Criar nova conta
            </CustomSpanLink>
          </div>
          <div className="login-content-form">
            <StyledButton
              id="BotaoCriarNovoChamado"
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={() => {
                history.push(
                  b2bValue ? '/nova-solicitacao/?b2b=true' : '/nova-solicitacao'
                );
              }}
            >
              Criar novo chamado
            </StyledButton>

            <Typography variant="h3" className="login-subtitle">
              <span>Ou faça login</span>
            </Typography>
            <Login />
          </div>
        </div>
        <div className="login-background" />
      </LoginContainer>
      <ConfirmLoginDialog
        openState={confirmModalOpened}
        closeModal={() => setConfirmModalOpened(false)}
        handleConfirm={handleConfirm}
        waiting={waiting}
      />
    </>
  );
};

export default LoginView;
