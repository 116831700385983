import { Button, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../components/Logo';
import './headerStyles.css';
import getQueryParams from '../../utils/getQueryParams';

export default function Header() {
  const theme = useTheme();
  const smDown = theme.breakpoints.down('sm');
  const bigScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const b2bValue = getQueryParams('b2b');
  const useStyles = makeStyles(themeStyles => ({
    toolbar: {
      justifyContent: 'space-between',
      paddingRight: '0',
      paddingLeft: '0'
    },
    buttonContainer: {
      '& > *': {
        margin: themeStyles.spacing(1)
      },
      display: !bigScreen && 'flex',
      flexDirection: !bigScreen && 'column'
    },
    link: {
      color: themeStyles.palette.primary.main,
      textDecoration: 'none'
    }
  }));
  const classes = useStyles();
  // const [loginMenuOpenState, setLoginMenuOpenState] = React.useState(null);
  // const preventMenuFromClosingWhenPressingTab = event => {
  //   if (event.keyCode === 9 || event.keyCode === 65) {
  //     event.stopPropagation();
  //   }
  // };
  return (
    <>
      <Toolbar className={classes.toolbar}>
        <Link to={b2bValue ? '/?b2b=true' : '/'}>
          <Logo />
        </Link>
        <div className={classes.buttonContainer}>
          <Button
            href={
              b2bValue
                ? 'http://acerempresas.com.br'
                : 'https://br-store.acer.com'
            }
            style={{ textTransform: 'none', right: smDown ? 0 : 70 }}
            color="primary"
            size="small"
          >
            Retornar a página inicial
          </Button>
        </div>
      </Toolbar>
    </>
  );
}
