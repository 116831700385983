import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Logo from '../../components/Logo';
import AuthService from '../../auth/auth.service';
import NavbarLinks from './NavbarLinks/NavbarLinks';
import getQueryParams from '../../utils/getQueryParams';

const useStyles = makeStyles(() => ({
  root: { backgroundColor: '#e8f5e9' },
  avatar: {
    width: 60,
    height: 60
  }
}));
const b2bValue = getQueryParams('b2b');
const menuItems = [
  {
    id: 1,
    path: `/tickets/${b2bValue ? '?b2b=true' : ''}`,
    text: 'Tickets'
  }
];

const TopBar = ({ className, onMobileNavOpen, mobilenavopen, ...rest }) => {
  const [redirect] = React.useState(useHistory());
  const classes = useStyles();
  const [notifications] = useState([]);

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <Logo />
        <Box flexGrow={1} />
        <Hidden mdDown>
          <IconButton>
            <Badge badgeContent={notifications.length} variant="dot">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Tooltip title="Sair">
            <IconButton
              onClick={() => {
                AuthService.logout(redirect);
              }}
            >
              <InputIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <NavbarLinks menuItems={menuItems} />
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
  mobilenavopen: PropTypes.bool
};

TopBar.defaultProps = {
  className: '',
  onMobileNavOpen: () => null,
  mobilenavopen: true
};

export default TopBar;
