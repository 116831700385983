/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import RefreshIcon from '@material-ui/icons/Refresh';
import SendIcon from '@material-ui/icons/Send';
import React, { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import Loader from '../../../utils/Loader';
import API from '../../../services/api';
import Page from '../../../components/Page';
import Messages from '../messages/messages';
import SatisfactionScore from '../Satisfaction/SatisfactionScore';
import TicketStatusChip from './TicketStatusChip';
import getQueryParams from '../../../utils/getQueryParams';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const TicketContainer = ({ match }) => {
  const classes = useStyles();
  const history = useHistory();
  const [filesToUpload, setFilesToUpload] = React.useState([]);
  const ticketId = match.params.id;
  const [ticketData, setTicketData] = useState({
    marketplaceGenericMessages: []
  });
  const b2bValue = getQueryParams('b2b');
  const [loading, setLoading] = useState(false);

  const onDrop = acceptedFiles => {
    const newFilesState = [...filesToUpload];
    if (newFilesState.length === 4) {
      enqueueSnackbar('Não é possível incluir mais que 4 imagens', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      return;
    }
    acceptedFiles.forEach(element => {
      if (element.size > 2000000) {
        enqueueSnackbar(
          `A imagem '${element.path}' não pode ser enviada pois ela tem mais de 2MB`,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }
          }
        );
        return;
      }
      const reader = new FileReader();
      const fileByteArray = [];
      reader.readAsArrayBuffer(element);
      reader.onloadend = evt => {
        if (evt.target.readyState === FileReader.DONE) {
          const arrayBuffer = evt.target.result;
          const array = new Uint8Array(arrayBuffer);
          for (let i = 0; i < array.length; i++) {
            fileByteArray.push(array[i]);
          }
        }
      };
      newFilesState.push({
        path: element.path,
        binary: fileByteArray
      });
    });
    setFilesToUpload(newFilesState);
  };

  const files = filesToUpload.map((file, index) => (
    <Chip
      key={file.path}
      style={{ margin: '2px' }}
      label={file.path}
      onDelete={() => {
        const newFilesState = [...filesToUpload];
        newFilesState.splice(index, 1);
        setFilesToUpload(newFilesState);
      }}
    />
  ));

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    maxFiles: 4
  });

  const [newMessage, setNewMessage] = useState('');

  const updateChat = async () => {
    setLoading(true);
    const response = await API.get(`/tickets/${ticketId}`);
    setTicketData({ ...ticketData, ...response.data });
    setLoading(false);
  };

  useMemo(() => {
    updateChat();
    // eslint-disable-next-line
  }, [ticketId]);

  const sendNewMessage = () => {
    if (!newMessage && (!filesToUpload || filesToUpload.length === 0)) {
      enqueueSnackbar('Insira uma mensagem', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      return;
    }

    const postPayload = {
      ticketId,
      message: newMessage,
      attachments: filesToUpload.map(file => {
        return { name: file.path, content: file.binary };
      })
    };

    API.post(`/tickets/send-answer`, postPayload)
      .then(() => {
        enqueueSnackbar('Nova mensagem enviada com sucesso', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        });
        setNewMessage('');
        updateChat();
        setFilesToUpload([]);
      })
      .catch(() => {
        enqueueSnackbar(
          'Não foi possível enviar a mensagem, procure o atendimento técnico',
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }
          }
        );
      });
  };

  const { nicknames } = useSelector(state => state.nicknames);

  const findNickname = search => {
    const findedNickname = nicknames?.find(
      loadedNickname => loadedNickname.username === search
    );

    if (findedNickname?.nickname) {
      return findedNickname.nickname;
    }

    return search;
  };

  return (
    <Page className={classes.root} title="Ticket Container">
      <Container maxWidth={false}>
        <Breadcrumbs gutterBottom aria-label="breadcrumb">
          <Link color="inherit" to={`/tickets/${b2bValue ? '?b2b=true' : ''}`}>
            Tickets
          </Link>
          <Typography color="textPrimary">#{ticketId}</Typography>
        </Breadcrumbs>
        {ticketData?.mainTicket && ticketData?.mainTicket !== null && (
          <Alert severity="warning">
            Este Ticket agora é apenas para consulta. Caso queira enviar nova
            mensagem, por favor faça-o através do Ticket{' '}
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                history.push(`/ticket/${ticketData.mainTicket}`);
              }}
            >
              <strong>
                <u>{ticketData.mainTicket}</u>
              </strong>
            </span>
          </Alert>
        )}
        {loading ? (
          <Loader />
        ) : (
          <Box mt={3}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} xs={12}>
                <Card>
                  <CardContent>
                    <Grid container>
                      <Grid item lg={4}>
                        <Typography variant="overline"> Assunto:</Typography>
                        <Typography>{ticketData?.subject}</Typography>
                      </Grid>
                      <Grid item lg={4}>
                        <Typography variant="overline"> Atendente</Typography>
                        <Typography>
                          {findNickname(ticketData?.serviceAttendantLogin)}
                        </Typography>
                      </Grid>

                      <Grid item lg={4}>
                        <Typography variant="overline"> Status</Typography>
                        <div>
                          <TicketStatusChip data={ticketData?.ticketStatus} />
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                </Card>
                <SatisfactionScore data={ticketData} />
                <Card>
                  <Messages chat={ticketData?.marketplaceGenericMessages} />
                  <CardActions disableSpacing>
                    <Grid container spacing={0}>
                      <Grid item lg={9}>
                        <Box m={1}>
                          <TextField
                            value={newMessage}
                            onChange={htmlElemtEvent => {
                              setNewMessage(htmlElemtEvent.target.value);
                            }}
                            size="small"
                            fullWidth
                            label="Enviar mensagem"
                            variant="outlined"
                            multiline
                            minRows={4}
                          />
                          <Paper>
                            <input {...getInputProps()} />
                          </Paper>
                          <br />
                          {files}
                        </Box>
                      </Grid>
                      <Grid item lg={3}>
                        <Box m={1}>
                          <ButtonGroup
                            fullWidth
                            disableElevation
                            variant="contained"
                            color="primary"
                          >
                            {ticketData?.mainTicket !== null ? (
                              <Button disabled>
                                <SendIcon />
                              </Button>
                            ) : (
                              <Tooltip title="Enviar">
                                <Button
                                  onClick={() => {
                                    sendNewMessage();
                                  }}
                                >
                                  <SendIcon />
                                </Button>
                              </Tooltip>
                            )}
                            <Tooltip title="Atualizar">
                              <Button
                                onClick={() => {
                                  updateChat();
                                }}
                              >
                                <RefreshIcon />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Anexos">
                              <Button
                                {...getRootProps({ className: 'dropzone' })}
                              >
                                <AttachFileIcon />
                              </Button>
                            </Tooltip>
                          </ButtonGroup>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )}
      </Container>
    </Page>
  );
};

export default TicketContainer;
