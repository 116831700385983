import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router';
import authService from '../../auth/auth.service';
import Page from '../../components/Page';
import config from '../../config';
import formHelper from '../../utils/formHelper';
import API from '../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SetCpfView = ({ userId }) => {
  const classes = useStyles();

  const history = useHistory();
  const [formData, setFormdata] = useState(
    formHelper.simpleData2FormData({
      cpf: {
        required: true,
        masked: true
      }
    })
  );

  const formIsOK = () => {
    if (!formHelper.validaForm(formData, setFormdata)) {
      enqueueSnackbar('Preencha o cpf', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      return false;
    }
    return true;
  };

  const submitCpf = () => {
    if (!formIsOK()) {
      return;
    }
    const postData = formHelper.formData2PostData(formData);
    const finalPostData = { username: userId, cpf: postData.cpf };
    API.post(
      `${config.cognito.authUrl}/google-customerguide-set-cpf`,
      finalPostData
    )
      .then(() => {
        window.location.href = authService.getGoogleAuthUrl();
      })
      .catch(error => {
        if (
          error?.response?.data?.key ===
          'i18n.login.GOOGLE_USER_CPF_ALREADY_IN_USE'
        ) {
          enqueueSnackbar('CPF já em uso', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }
          });
          return;
        }
        enqueueSnackbar('Ocorreu um erro ao tentar salvar o CPF', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        });
      });
  };

  return (
    <Page className={classes.root}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box mb={3}>
            <Card>
              <CardContent>
                <Typography variant="h3">Informe seu CPF</Typography>
              </CardContent>
              <Divider />
              <CardContent>
                <Box mb={2}>
                  <InputMask
                    mask="999.999.999-99"
                    onChange={htmlElemtEvent => {
                      formHelper.setFormvalue(
                        formData,
                        setFormdata,
                        'cpf',
                        htmlElemtEvent.target.value
                      );
                    }}
                  >
                    {() => (
                      <TextField
                        error={formData.cpf.error}
                        label="CPF"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  </InputMask>
                </Box>
              </CardContent>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                pb={3}
                pt={3}
                pl={2}
                pr={2}
              >
                <Button color="primary" onClick={() => history.push('/')}>
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    submitCpf();
                  }}
                  color="primary"
                >
                  Cadastrar
                </Button>
              </Box>
            </Card>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default SetCpfView;
