import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  FormControl,
  Grid,
  IconButton,
  makeStyles,
  Modal,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Link
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router';
import config from '../../config';
import IconReportSearch from '../../icons/IconReportSearch';
import API from '../../services/api';
import formHelper from '../../utils/formHelper';
import getQueryParams from '../../utils/getQueryParams';
import LoginView from '../auth/LoginView';
import UploadIcon from './components/UploadIcon/UploadIcon';

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  },
  boxForm: {
    width: '602px',
    height: 'max-content',
    background: '#f8f7f7',
    padding: '30px 20px',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '402px',

      background: '#f8f7f7',
      margin: '20px auto'
    },
    [theme.breakpoints.up('md')]: {
      width: '602px',

      background: '#f8f7f7',
      margin: '20px auto'
    },
    [theme.breakpoints.up('lg')]: {
      width: '602px',

      background: '#f8f7f7',
      margin: '20px auto'
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loginHeaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '300px',
    margin: '100px auto'
  },
  customInput: {
    background: '#FAFAFA'
  },
  button: {
    width: 350,
    height: 66,
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '23px',
    textTransform: 'none',
    fontFamily: 'Acer foco,sans-serif',

    [theme.breakpoints.down('sm')]: {
      width: '290px',
      height: 56,
      fontSize: '14px',
      fontWeight: 400,
      textTransform: 'none',
      fontFamily: 'Acer foco,sans-serif'
    },
    [theme.breakpoints.up('md')]: {
      width: 350,
      height: 66,
      fontSize: '20px',
      fontWeight: 400,
      textTransform: 'none',
      fontFamily: 'Acer foco,sans-serif'
    }
  }
}));

const NewTicket = () => {
  const classes = useStyles();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [mascaraTelefone, setMascaraTelefone] = useState('(99)99999-9999');
  const [maskCpfCnpj, setMaskCpfCnpj] = React.useState('999.999.999-999');
  const [isLoading, setIsLoading] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const b2bValue = getQueryParams('b2b');
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [loginMenuOpenState, setLoginMenuOpenState] = React.useState(null);
  const preventMenuFromClosingWhenPressingTab = event => {
    if (event.keyCode === 9 || event.keyCode === 65) {
      event.stopPropagation();
    }
  };

  const convertFileToBase64 = async file => {
    if (!file) return;

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = error => {
        reject(error);
      };
    });
  };

  const onDrop = async acceptedFiles => {
    const newFilesState = [...filesToUpload];
    if (newFilesState.length + acceptedFiles.length > 4) {
      enqueueSnackbar('Não é possível incluir mais que 4 imagens', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      return;
    }
    const hasFileBiggerThan2mb = acceptedFiles.find(
      file => file.size > 2000000
    );
    if (hasFileBiggerThan2mb) {
      enqueueSnackbar(
        `A imagem ${hasFileBiggerThan2mb.path} não pode ser enviada pois ela tem mais de 2MB`,
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        }
      );
      return;
    }
    // eslint-disable-next-line
    for (const element of acceptedFiles) {
      // eslint-disable-next-line
      const base64 = await convertFileToBase64(element);
      newFilesState.push({
        path: element.path,
        binary: base64.split('base64,')[1]
      });
    }
    setFilesToUpload(newFilesState);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    maxFiles: 4
  });

  const files = filesToUpload.map((file, index) => (
    <Chip
      key={file.path}
      style={{ margin: '2px' }}
      label={file.path}
      onDelete={() => {
        const newFilesState = [...filesToUpload];
        newFilesState.splice(index, 1);
        setFilesToUpload(newFilesState);
      }}
    />
  ));

  const history = useHistory();
  const [formData, setFormdata] = useState(
    formHelper.simpleData2FormData({
      name: {
        required: true
      },
      cpf: {
        required: true,
        masked: true
      },
      email: {
        required: true
      },
      phone: {
        required: true,
        masked: true,
        leaveOnlyNumbersWhenSubmit: true
      },
      // reason: {
      //   required: false
      // },
      orderNumber: {
        required: false
      },
      subject: {
        required: true
      },
      description: {
        required: true
      }
    })
  );

  const submitForm = async () => {
    setIsLoading(true);
    if (formData?.cpf?.value) {
      formData.cpf.value = formData.cpf.value.replace(/\D/g, '');
    }

    if (
      formData?.cpf?.value.length > 1 &&
      formData?.cpf?.value.length <= 11 &&
      b2bValue
    ) {
      enqueueSnackbar('Ops, verifique se o campo cnpj está correto', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      setIsLoading(false);
      return;
    }

    if (!formHelper.validaForm(formData, setFormdata)) {
      enqueueSnackbar(
        'Ops, verifique se todos os campos estão preenchidos corretamente',
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        }
      );
      setIsLoading(false);
      return;
    }

    const postData = formHelper.formData2PostData(formData);
    postData.attachments = filesToUpload.map(file => {
      return {
        name: file.path,
        content: file.binary
      };
    });
    postData.recaptchatoken = await executeRecaptcha('recaptcha');

    API.post(config.backend.NewTicketUrl, {
      ...postData,
      marketplace: b2bValue ? 'ACER STORE B2B' : 'ACER STORE B2C'
    })
      .then(response => {
        const ticketId = response?.data?.message?.params?.[0];
        enqueueSnackbar(`Novo chamado realizado com sucesso!`, {
          className: 'new-ticket-snackbar',
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          persist: true,
          action: () => (
            <div
              style={{
                display: 'flex',
                flexFlow: 'column wrap'
              }}
            >
              <div id="divNumeroDoChamado">
                <span id="spanNumeroDoChamado">
                  Número do chamado <strong>#{ticketId}</strong>
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'row wrap',
                  justifyContent: 'space-between',
                  marginTop: '8px'
                }}
              >
                <small style={{ maxWidth: '100%' }}>
                  Faça o login ou cadastre uma nova conta para acompanhar seu
                  pedido
                </small>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => closeSnackbar()}
                  style={{
                    border: '#fff',
                    color: '#fff',
                    float: 'right'
                  }}
                >
                  Fechar
                </Button>
              </div>
            </div>
          )
        });
        history.push(`/login${b2bValue ? '?b2b=true' : ''}`);
      })
      .catch(() => {
        enqueueSnackbar(
          'Não foi possível realizar o chamado, procure o atendimento técnico',
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }
          }
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCpfCnpjMask = value => {
    value = value.replace(/\D/g, '');
    if (value.length > 11) {
      setMaskCpfCnpj('99.999.999/9999-99');
    } else if (value.length <= 11) {
      setMaskCpfCnpj('999.999.999-99');
    }
  };

  return (
    <Box>
      {/* <Breadcrumbs gutterBottom aria-label="breadcrumb">
        <Link className={classes.link} to={`${b2bValue ? '/?b2b=true' : '/'}`}>
          Fale Conosco Acer
        </Link>
      </Breadcrumbs> */}
      <Box
        display="flex"
        flexDirection={smDown ? 'column-reverse' : ''}
        width="100%"
        mt={10}
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Box className={classes.boxForm}>
            <Grid container spacing={2}>
              <Grid item sm={12} lg={12}>
                <Box mb={1}>
                  <Typography
                    variant="h3"
                    style={{
                      color: '#343434',
                      fontSize: '20px',
                      fontWeight: 800
                    }}
                  >
                    Novo Chamado
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <Box mb={2}>
                  <TextField
                    error={formData.name.error}
                    id="InputLabelName"
                    label={b2bValue ? 'Nome da empresa' : 'Nome completo'}
                    variant="outlined"
                    size="small"
                    style={{ background: '#fff' }}
                    fullWidth
                    onChange={htmlElemtEvent => {
                      formHelper.setFormvalue(
                        formData,
                        setFormdata,
                        'name',
                        htmlElemtEvent.target.value
                      );
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <Box mb={2}>
                  <FormControl fullWidth>
                    <InputMask
                      onPaste={e =>
                        handleCpfCnpjMask(e.clipboardData.getData('Text'))
                      }
                      mask={maskCpfCnpj}
                      maskChar=""
                      size="small"
                      helperText={
                        b2bValue
                          ? 'CNPJ Titular da compra.'
                          : 'CPF/CNPJ Titular da compra.'
                      }
                      onChange={htmlElemtEvent => {
                        formHelper.setFormvalue(
                          formData,
                          setFormdata,
                          'cpf',
                          htmlElemtEvent.target.value
                        );
                        if (htmlElemtEvent.target.value.length > 14) {
                          setMaskCpfCnpj('99.999.999/9999-99');
                        } else {
                          setMaskCpfCnpj('999.999.999-999');
                        }
                      }}
                    >
                      {() => (
                        <TextField
                          error={formData.cpf.error}
                          id="InputLabelCpfCnpj"
                          label={b2bValue ? 'CNPJ' : 'CPF/CNPJ'}
                          InputProps={{
                            classes: {
                              root: classes.customInput // Aplicando a classe personalizada para o TextField dentro do InputProps
                            }
                          }}
                          variant="outlined"
                          size="small"
                          helperText={
                            b2bValue
                              ? 'CNPJ Titular da compra.'
                              : 'CPF/CNPJ Titular da compra.'
                          }
                          fullWidth
                        />
                      )}
                    </InputMask>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <Box mb={2}>
                  <TextField
                    onChange={htmlElemtEvent => {
                      formHelper.setFormvalue(
                        formData,
                        setFormdata,
                        'email',
                        htmlElemtEvent.target.value
                      );
                    }}
                    size="small"
                    error={formData.email.error}
                    style={{ background: '#fff' }}
                    type="email"
                    id="InputLabelEmail"
                    label="Email"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <Box mb={2}>
                  <InputMask
                    mask={mascaraTelefone}
                    onChange={htmlElemtEvent => {
                      formHelper.setFormvalue(
                        formData,
                        setFormdata,
                        'phone',
                        htmlElemtEvent.target.value
                      );
                    }}
                    size="small"
                    onBlur={htmlElemtEvent => {
                      formHelper.telefoneFlex(
                        'phone',
                        formData,
                        setFormdata,
                        setMascaraTelefone,
                        htmlElemtEvent
                      );
                    }}
                    onFocus={() => {
                      setMascaraTelefone('(99)99999-9999');
                    }}
                  >
                    {() => (
                      <TextField
                        error={formData.phone.error}
                        style={{ background: '#fff' }}
                        id="InputLabelTelefone"
                        label="Telefone"
                        size="small"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  </InputMask>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Box mb={2}>
                  <TextField
                    onChange={htmlElemtEvent => {
                      formHelper.setFormvalue(
                        formData,
                        setFormdata,
                        'orderNumber',
                        htmlElemtEvent.target.value
                      );
                    }}
                    size="small"
                    error={formData.orderNumber.error}
                    style={{ background: '#fff' }}
                    id="InputLabelNumPedido"
                    label="Número de Pedido (Opcional)"
                    defaultValue={getQueryParams('orderId') ?? ''}
                    variant="outlined"
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Box mb={2}>
                  <TextField
                    onChange={htmlElemtEvent => {
                      formHelper.setFormvalue(
                        formData,
                        setFormdata,
                        'subject',
                        htmlElemtEvent.target.value
                      );
                    }}
                    size="small"
                    error={formData.subject.error}
                    style={{ background: '#fff' }}
                    id="InputLabelAssunto"
                    label="Assunto"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                {/* <Box mb={2}>
              <FormControl
                error={formData.reason.error}
                fullWidth
                variant="outlined"
              >
                <InputLabel>Motivo</InputLabel>
                <Select
                  value={formData.reason.value}
                  onChange={htmlElemtEvent => {
                    formHelper.setFormvalue(
                      formData,
                      setFormdata,
                      'reason',
                      htmlElemtEvent.target.value
                    );
                  }}
                  label="Motivo"
                >
                  <MenuItem value="DELIVERY_INFORMATION">
                    Informações de entrega
                  </MenuItem>
                  <MenuItem value="ORDER_PAYMENT_INFORMATION">
                    Informações sobre o pagamento do pedido
                  </MenuItem>
                  <MenuItem value="COMPLIMENT">Elogio</MenuItem>
                  <MenuItem value="REGRET_BY_RETURN">
                    Devolução por arrependimento
                  </MenuItem>
                  <MenuItem value="ORDER_CANCELLATION">
                    Cancelamento do pedido
                  </MenuItem>
                  <MenuItem value="FAILURE_EXCHANGE">
                    Troca por falha no produto
                  </MenuItem>
                  <MenuItem value="OTHER">Outros</MenuItem>
                </Select>
              </FormControl>
            </Box> */}
                <Box mb={2}>
                  <TextField
                    onChange={htmlElemtEvent => {
                      formHelper.setFormvalue(
                        formData,
                        setFormdata,
                        'description',
                        htmlElemtEvent.target.value
                      );
                    }}
                    size="small"
                    error={formData.description.error}
                    style={{ background: '#fff' }}
                    fullWidth
                    id="InputLabelDescricao"
                    label="Descrição"
                    multiline
                    minRows={4}
                    variant="outlined"
                  />
                </Box>

                <Typography variant="overline">Enviar arquivos</Typography>
                <Paper
                  {...getRootProps({ className: 'dropzone' })}
                  style={{
                    padding: '20px',
                    border: 'dashed 2px #ccc',
                    textAlign: 'center'
                  }}
                  variant="outlined"
                >
                  <input id="InputLabelAnexos" {...getInputProps()} />
                  <UploadIcon />
                  <Typography>
                    <b>Arraste os arquivos ou clique para adicionar</b> <br />
                    (No máximo 4 imagens JPG ou PNG de até 2MB cada)
                  </Typography>
                </Paper>
                <br />
                {files}
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Box mt={1}>
                  <Button
                    disabled={isLoading}
                    variant="contained"
                    color="primary"
                    style={{ width: '100%' }}
                    size="large"
                    id="BotaoEnviar"
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    Enviar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          display="flex"
          width="100%"
          justifyContent={smDown ? 'center' : ''}
          alignItems={smDown ? 'center' : ''}
          padding="10px"
        >
          <Grid container justifyContent="center">
            <Grid item lg={12}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box display="flex" flexDirection="column" mb={10}>
                  <Box component="span" mb={2}>
                    <Typography
                      style={{
                        fontSize: smDown ? '24px' : '40px',
                        color: '#343434',
                        fontWeight: '800'
                      }}
                    >
                      Acompanhe seu chamado
                    </Typography>
                  </Box>
                  <Box mb={2}>
                    <Typography
                      variant="body1"
                      style={{
                        color: '#454545',
                        fontSize: smDown ? '14px' : '18px',
                        maxWidth: smDown ? 402 : ''
                      }}
                    >
                      {` Após preencher o formulário ao lado, você poderá
                      acompanhar`}
                      {smDown ? '' : <br />}
                      {`sua solicitação clicando em "acompanhe seu
                      chamado" abaixo.`}
                    </Typography>
                  </Box>
                  <Box component="span" mb={2}>
                    <Button
                      startIcon={
                        <IconReportSearch
                          fontSize="small"
                          style={{
                            marginRight: '24px',
                            fontSize: smDown ? 18 : 25
                          }}
                        />
                      }
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      onClick={event => {
                        setLoginMenuOpenState(event.currentTarget);
                      }}
                    >
                      Acompanhe seu chamado
                    </Button>
                  </Box>
                  <Modal
                    open={Boolean(loginMenuOpenState)}
                    onClose={() => {
                      setLoginMenuOpenState(null);
                    }}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    keepMounted
                  >
                    <Card
                      onKeyDown={preventMenuFromClosingWhenPressingTab}
                      className={classes.loginHeaderContainer}
                    >
                      <CardContent>
                        <div className="loginMenuHeader">
                          <Typography variant="overline">
                            Acompanhar atendimento
                          </Typography>
                          <Box>
                            <IconButton
                              onClick={() => {
                                setLoginMenuOpenState(null);
                              }}
                              size="small"
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          </Box>
                        </div>
                        <LoginView onlyLogin />
                        <Link
                          className={classes.link}
                          onClick={() => {
                            setLoginMenuOpenState(null);
                          }}
                          href={
                            b2bValue
                              ? 'http://acerempresas.com.br/register'
                              : `https://br-store.acer.com/login?returnUrl=%2Faccount`
                          }
                        >
                          <Typography>Criar nova conta</Typography>
                        </Link>
                        <Link
                          style={{ marginLeft: '2em' }}
                          className={classes.link}
                          onClick={() => {
                            setLoginMenuOpenState(null);
                          }}
                          href={
                            b2bValue
                              ? process.env.REACT_APP_LOGIN_B2B_REDIRECT
                              : 'https://br-store.acer.com/login'
                          }
                        >
                          <Typography>Esqueci minha senha</Typography>
                        </Link>
                      </CardContent>
                    </Card>
                  </Modal>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default NewTicket;
