import { createSlice } from '@reduxjs/toolkit';

export const nicknameSlice = createSlice({
  name: 'nicknames',
  initialState: {
    nicknames: [],
    updated: null
  },
  reducers: {
    load: (state, action) => {
      state.nicknames = action.payload;
      state.updated = new Date();
    }
  }
});

export const { load } = nicknameSlice.actions;

export default nicknameSlice.reducer;
