/*eslint-disable */
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
  Typography,
  Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import UpdateIcon from '@material-ui/icons/Update';
import clsx from 'clsx';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import config from '../../config';
import { AUTHORIZATION_API } from '../../services/api';
import getQueryParams from '../../utils/getQueryParams';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  margin: {
    margin: theme.spacing(0)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: '100%'
  }
}));

const Login = () => {
  const classes = useStyles();
  const b2bValue = getQueryParams('b2b');
  const history = useHistory();
  const location = useLocation();
  const [formValues, setFormValues] = useState({
    username: '',
    password: '',
    showPassword: false
  });
  const handleSuccess = data => {
    if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
      sessionStorage.setItem('session', data.session);
      history.push('/change-password', {
        state: {
          username: formValues.username,
          password: formValues.password
        }
      });
      return;
    }
    const { idToken, refreshToken } = data;
    localStorage.setItem('idToken', idToken);
    localStorage.setItem('accessToken', idToken);
    localStorage.setItem('refreshToken', refreshToken);
    if (location.state?.link) history.push(location.state?.link);
    else history.push(`/tickets/${b2bValue ? '?b2b=true' : ''}`);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const { username, password } = formValues;

    try {
      const { data } = await AUTHORIZATION_API.post(
        `${config.cognito.authUrl}/customerguide-login/${
          b2bValue ? '?b2b=true' : ''
        }`,
        {
          username,
          password
        }
      );
      handleSuccess(data);
    } catch (error) {
      if (
        error.response.data.key === 'i18n.login.USER_NOT_FOUND' ||
        error.response.data.key === 'i18n.login.NOT_AUTHORIZED'
      ) {
        enqueueSnackbar('Usuário e/ou senha inválidos, verifique!', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        });
      } else {
        enqueueSnackbar('Erro ao efetuar o Login.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        });
      }
    }
  };

  const handleClickShowPassword = () => {
    setFormValues({ ...formValues, showPassword: !formValues.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const [accessMode, setAccessMode] = useState('emailAndPassword');
  const handleAccessModeChange = () => {
    setAccessMode(
      accessMode === 'emailAndPassword'
        ? 'verificationCode'
        : 'emailAndPassword'
    );
  };

  const [verificationCodeMode, setVerificationCodeMode] = useState(
    'enterEmail'
  );
  const handleVerificationCodeMode = () => {
    setVerificationCodeMode(
      verificationCodeMode === 'enterEmail' ? 'verificationCode' : 'enterEmail'
    );
  };

  const [email, setEmail] = useState('');

  const handleVerificationCodeRequest = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      enqueueSnackbar('Insira um endereço de e-mail válido', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      return;
    }

    AUTHORIZATION_API.post(
      `${config.cognito.authUrl}/vtex-faleconosco-accesskey-send`,
      { email }
    )
      .then(() => {
        enqueueSnackbar(`Código de autorização enviado com sucesso.`, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        });
      })
      .catch(error => {
        enqueueSnackbar(
          'Não foi possível enviar um código de autorização. Tente novamente.',
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }
          }
        );
      });
  };

  const [verificationCode, setVerificationCode] = useState('');

  const handleValidateVerificationCode = () => {
    if (verificationCode.length !== 6) {
      enqueueSnackbar('Por favor, insira um código de acesso com 6 dígitos.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      return;
    }

    AUTHORIZATION_API.post(
      `${config.cognito.authUrl}/vtex-faleconosco-accesskey-validate`,
      { email, accessKey: verificationCode }
    )
      .then(response => {
        handleSuccess(response.data);
      })
      .catch(error => {
        enqueueSnackbar(
          'Código de acesso inválido. Solicite um novo código de acesso e tente novamente.',
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }
          }
        );
      });
  };

  const formatTime = time => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const [timeRemaining, setTimeRemaining] = useState(600);

  useEffect(() => {
    if (verificationCodeMode !== 'verificationCode') return;
    const timer = setInterval(() => {
      setTimeRemaining(prevTime => prevTime - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
      setTimeRemaining(600);
    };
  }, [verificationCodeMode]);

  const [timeoutRequestDisable, setTimeoutRequestDisable] = useState(60);

  useEffect(() => {
    if (verificationCodeMode !== 'verificationCode') return;
    if (timeoutRequestDisable === 0 || timeoutRequestDisable < 0) return;
    const buttonDisableTimer = setInterval(() => {
      setTimeoutRequestDisable(prevTime => prevTime - 1);
    }, 1000);

    return () => {
      clearInterval(buttonDisableTimer);
    };
  }, [verificationCodeMode, timeoutRequestDisable]);

  const isButtonDisabled = timeoutRequestDisable > 0;

  return (
    <>
      {accessMode === 'emailAndPassword' ? (
        <div className="login-content-form">
          <form onSubmit={handleSubmit}>
            <Box paddingTop={3}>
              <TextField
                label="Email"
                variant="outlined"
                type="email"
                fullWidth
                value={formValues?.username}
                onChange={e =>
                  setFormValues({ ...formValues, username: e.target.value })
                }
              />
            </Box>
            <Box paddingTop={3}>
              <div>
                <FormControl
                  variant="outlined"
                  className={clsx(classes.margin, classes.textField)}
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Senha
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={formValues.showPassword ? 'text' : 'password'}
                    value={formValues?.password}
                    onChange={e =>
                      setFormValues({ ...formValues, password: e.target.value })
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {formValues.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={50}
                  />
                </FormControl>
              </div>
            </Box>
            <Box paddingTop={3}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!formValues?.username || !formValues?.password}
              >
                Login
              </Button>
            </Box>
          </form>
          <Box paddingTop={3}>
            <Button
              variant="outlined"
              color="default"
              onClick={handleAccessModeChange}
            >
              Entrar com código de acesso
            </Button>
          </Box>
          <div className="social-btn">
            <Link
              variant="body2"
              href={
                b2bValue
                  ? process.env.REACT_APP_LOGIN_B2B_REDIRECT
                  : 'https://br-store.acer.com/login'
              }
              target="_blank"
              style={{ textDecoration: 'none' }}
            >
              Esqueci minha senha
            </Link>
          </div>
        </div>
      ) : (
        <>
          {verificationCodeMode === 'enterEmail' ? (
            <div className="verification-code-request-form">
              <Box paddingTop={3}>
                <TextField
                  label="Email"
                  variant="outlined"
                  type="email"
                  fullWidth
                  onChange={e => setEmail(e.target.value)}
                />
              </Box>
              <Box paddingTop={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleVerificationCodeRequest();
                    handleVerificationCodeMode();
                  }}
                >
                  Enviar código
                </Button>
              </Box>
              <Box paddingTop={3}>
                <Button
                  variant="outlined"
                  color="default"
                  onClick={handleAccessModeChange}
                >
                  Entrar com e-mail e senha
                </Button>
              </Box>
            </div>
          ) : (
            <div className="verification-code-input-form">
              <Typography style={{ fontSize: '14px' }}>
                Insira o código enviado no e-mail: <strong>{email}</strong>
              </Typography>

              <Box paddingTop={1}>
                <TextField
                  key="verification-code"
                  label="Código de acesso"
                  variant="outlined"
                  onChange={e => setVerificationCode(e.target.value)}
                  fullWidth
                />
              </Box>
              {timeRemaining > 0 ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: '12px',
                    gap: '6px'
                  }}
                >
                  <UpdateIcon style={{ fontSize: '20px', color: '#FF8B27' }} />
                  <Typography style={{ fontSize: '14px' }}>
                    Seu código de acesso irá expirar em{' '}
                    <strong style={{ color: '#FF8B27' }}>
                      {formatTime(timeRemaining)}.
                    </strong>
                  </Typography>
                </div>
              ) : (
                <Typography style={{ fontSize: '14px', paddingTop: '12px' }}>
                  Seu código de acesso expirou.
                </Typography>
              )}
              <Box paddingTop={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleValidateVerificationCode();
                  }}
                >
                  Entrar
                </Button>
              </Box>
              <Box paddingTop={3}>
                <Button
                  variant="outlined"
                  color="default"
                  onClick={() => {
                    handleVerificationCodeMode();
                  }}
                >
                  Voltar
                </Button>
              </Box>
              <Box paddingTop={2}>
                <Tooltip
                  title={
                    isButtonDisabled
                      ? `Aguarde ${timeoutRequestDisable} segundos para solicitar um novo código.`
                      : ''
                  }
                >
                  <span>
                    <Button
                      variant="text"
                      color="default"
                      onClick={() => {
                        handleVerificationCodeRequest();
                        setTimeoutRequestDisable(60);
                      }}
                      disabled={isButtonDisabled}
                    >
                      Não recebi o código
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Login;
