export default {
  siteUri: process.env.REACT_APP_URI,
  recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  cognito: {
    authUrl: process.env.REACT_APP_JAVA_AUTH_URL,
    logoutUrl: process.env.REACT_APP_COGNITO_LOGOUT_URI,
    cognitoAuthUrl: process.env.REACT_APP_COGNITO_AUTH_URL,
    appClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    redirectUri: process.env.REACT_APP_COGNITO_REDIRECT_URI,
    newUserUrl: process.env.REACT_APP_COGNITO_NEWUSER_URL
  },
  backend: {
    url: process.env.REACT_APP_BACKEND_URI,
    NewTicketUrl: process.env.REACT_APP_NEW_TICKET_URL,
    knowledgeBaseUrl: process.env.REACT_APP_KNOWLEDGE_BASE_URL
  }
};
