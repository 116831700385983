import moment from 'moment';

/**
 * This function will format the informed
 * date, adding the correct timezone
 * for the currently user.
 * @param {*} date Date without formataion
 * @param {*} format The wanted format output date, default value is "DD/MM/YYYY HH:mm"
 */
const timezoneFormat = (date, format = 'DD/MM/YYYY HH:mm') => {
  const userTimeZone = new Date().getTimezoneOffset();

  return moment
    .utc(date)
    .utcOffset(userTimeZone * -1)
    .format(format);
};

export default timezoneFormat;
