import React from 'react';
import { Container } from '@material-ui/core';
import Page from '../components/Page';

const DataDeletionLGPDView = () => {
  return (
    <Page title="Política de privacidade">
      <Container maxWidth={false}>
        <h2>Instruções para solicitação de exclusão de dados.</h2>
        <br />
        <p>
          Envie um email para contato@atendimentoabo.com com seus dados (nome
          completo e email) solicitando a exclusão dos seus dados.
        </p>
      </Container>
    </Page>
  );
};

export default DataDeletionLGPDView;
