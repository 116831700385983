import React from 'react';
import { Route } from 'react-router-dom';
import DashboardLayout from '../../layouts/DashboardLayout';
// import AuthService from '../../auth/auth.service';

const DashboardPrivateRoute = ({ component: Component, ...rest }) => {
  // const location = useLocation();

  return (
    <Route
      {...rest}
      render={props => (
        <DashboardLayout>
          <Component {...props} key={Date.now()} />
        </DashboardLayout>
      )}
    />
  );
};

export default DashboardPrivateRoute;
